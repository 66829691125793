import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NatalFelizService } from "./natal-feliz.service";

interface FeedBack {
  active: boolean;
  type?: "fb-success" | "fb-error";
  message?: string;
}

@Component({
  selector: "app-natal-feliz",
  templateUrl: "./natal-feliz.component.html",
  styleUrls: ["./natal-feliz.component.sass"],
  providers: [NatalFelizService],
})
export class NatalFelizComponent implements OnInit {
  title: String = "Sorteio Natal Feliz";
  form: FormGroup;

  loading: boolean = false;

  feedback: FeedBack = {
    active: false,
  };

  constructor(
    private fb: FormBuilder,
    private natalFelizService: NatalFelizService
  ) {}

  ngOnInit() {
    this.criarFormulario();
  }

  criarFormulario() {
    this.form = this.fb.group({
      name: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(255)]),
      ],
      phone: ["", Validators.required],
      email: ["", Validators.compose([Validators.required, Validators.email])],
      number_pay: [
        "",
        Validators.compose([Validators.required, Validators.min(1)]),
      ],
      channel: ["TV"],
    });
  }

  async enviarDados() {
    this.loading = true;
    try {
      if (!this.form.valid) {
        return;
      }

      await this.natalFelizService.submitToForm(this.form.value);
    } catch (err) {
      this.feedback = {
        active: true,
        type: "fb-success",
        message: "Inscrição realizada com sucesso.",
      };

      this.form.reset();
    } finally {
      this.loading = false;
    }
  }
}
