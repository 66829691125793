import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cadastro-empresa',
  templateUrl: './cadastro-empresa.component.html',
  styleUrls: ['./cadastro-empresa.component.sass']
})
export class CadastroEmpresaComponent implements OnInit {

  title: String = 'cadastre sua empresa'
  fnRouter: boolean = false

  constructor(private readonly router: ActivatedRoute) { 
    this.fnRouter = !!this.router.snapshot.url.find(it => it.path === 'cadastro-empresa-fn')
  }

  ngOnInit() {}

}
