import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termos-de-uso',
  templateUrl: './termos-de-uso.component.html',
  styleUrls: ['./termos-de-uso.component.sass']
})
export class TermosDeUsoComponent implements OnInit {
  title: String = "Termos de uso"
  constructor() { }

  ngOnInit() {
  }

}
