import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class NatalFelizService {
  constructor(private http: HttpClient) {}

  submitToForm(data) {
    const formData = new FormData();

    formData.append("entry.787418713", data.email);
    formData.append("entry.986074553", data.number_pay);
    formData.append("entry.1212348438", data.phone);
    formData.append("entry.1884265043", data.name);
    formData.append("entry.413979250", data.channel);

    return this.http
      .post<any>(
        "https://docs.google.com/forms/d/e/1FAIpQLSdaZBU2Lcre1_RjwDvLB4YAivp-zQ9lGlidZfKeBOjRubbhoQ/formResponse",
        formData
      )
      .toPromise();
  }
}
