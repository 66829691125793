import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-franqueado',
  templateUrl: './franqueado.component.html',
  styleUrls: ['./franqueado.component.sass']
})
export class FranqueadoComponent implements OnInit {

  confirm = false

  constructor() { }

  ngOnInit() {
  }

}
