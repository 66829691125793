import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Api } from "src/app.routing";


@Injectable()
export class ContatoService {
    api = Api.host
    constructor(private http: HttpClient) { }

    enviarContato(body) {
        return this.http.post<any>(`${this.api}/contato/enviar`, body).toPromise()
    }

}