import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-suporte',
  templateUrl: './suporte.components.html',
  styleUrls: ['./suporte.components.sass']
})

export class SuporteComponents implements OnInit {
  ngOnInit(): void {

  }
}
