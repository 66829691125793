import { TrabalheConoscoService } from './trablhe-conosco.service';
import { Trabalho } from './trabalho';
import { debounceTime } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

declare let $: any;



@Component({
  selector: 'app-trabalhe-conosco',
  templateUrl: './trabalhe-conosco.component.html',
  styleUrls: ['./trabalhe-conosco.component.sass'],
  providers: [
    TrabalheConoscoService
  ],
})
export class TrabalheConoscoComponent implements OnInit {

  file: Array<File>
  message: any
  form: FormGroup
  error: boolean = false
  enviar: boolean = false

  constructor(private fb: FormBuilder, private trabalheConoscoService: TrabalheConoscoService) { }

  ngOnInit() {
    this.criarFormulario(new Trabalho())
  }

  criarFormulario(trabalho: Trabalho) {
    this.form = this.fb.group({
      nome: [trabalho.nome, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(120)])],
      telefone: [trabalho.telefone, Validators.compose([Validators.required, Validators.maxLength(11)])],
      email: [trabalho.email, Validators.compose([Validators.email, Validators.required, Validators.maxLength(160)])],
      cpf: [trabalho.cpf, Validators.compose([Validators.required, Validators.maxLength(14)])],
      anexo: [trabalho.anexo, Validators.compose([Validators.required])],
      area: [trabalho.area, Validators.compose([Validators.required])]
    })
  }

  fileChange(element) {
    this.file = element.target.files;
  }

  async enviarDados() {
    if (this.form.valid) {
      this.enviar = true
      let dados = this.form.value

      for (var i = 0; i < this.file.length; i++) {
        const split = this.file[i].name.split('.')
        dados.extensao = split[split.length - 1]
      }

      this.trabalheConoscoService.enviarCurriculo(dados, this.file).then(
        response => {
          this.message = response
          this.showModal()
          this.form.reset()
          this.enviar = false
        }
      ).catch(err => {
        console.error(err)
      })

    } else {
      this.error = true
    }

  }

  showModal(): void {
    $("#responseModal").modal('show');
  }

}
