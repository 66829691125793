import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cadastro-convenio',
  templateUrl: './cadastro-convenio.component.html',
  styleUrls: ['./cadastro-convenio.component.sass']
})
export class CadastroConvenioComponent implements OnInit {

  title: String = 'Cadastre seu convênio'

  constructor() { }

  ngOnInit() {
  }

}
