import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { routing } from "./app.routing";
import { CadastroEmpresaComponent } from "./pages/cadastro-empresa/cadastro-empresa.component";
import { CadastroConvenioComponent } from "./pages/cadastro-convenio/cadastro-convenio.component";
import { ContatoComponent } from "./pages/contato/contato.component";
import { MarketingComponent } from "./pages/marketing/marketing.component";
import { DownloadComponent } from "./pages/download/download.component";
import { FooterComponent } from "./pages/footer/footer.component";
import { FranqueadoComponent } from "./pages/franqueado/franqueado.component";
import { HomeComponent } from "./pages/home/home.component";
import { NavbarComponent } from "./pages/navbar/navbar.component";
import { NotfoundComponent } from "./pages/notfound/notfound.component";
import { PoliticaDePrivacidadeComponent } from "./pages/politica-de-privacidade/politica-de-privacidade.component";
import { NatalFelizComponent } from "./pages/sorteios/natal-feliz/natal-feliz.component";
import { SuporteComponents } from "./pages/suporte/suporte.components";
import { TermosDeUsoComponent } from "./pages/termos-de-uso/termos-de-uso.component";
import { TrabalheConoscoComponent } from "./pages/trabalhe-conosco/trabalhe-conosco.component";
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    FooterComponent,
    TermosDeUsoComponent,
    PoliticaDePrivacidadeComponent,
    CadastroEmpresaComponent,
    CadastroConvenioComponent,
    FranqueadoComponent,
    DownloadComponent,
    ContatoComponent,
    MarketingComponent,
    NotfoundComponent,
    TrabalheConoscoComponent,
    SuporteComponents,
    NatalFelizComponent,
    BreadcrumbComponent,
  ],
  imports: [
    BrowserModule,
    routing,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [HttpClient],
  bootstrap: [AppComponent],
})
export class AppModule {}
