import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Contato } from './contato';
import { ContatoService } from './contato.service'

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.sass'],
  providers: [
    ContatoService
  ],
})
export class ContatoComponent implements OnInit {

  title: String = 'Contato'
  status = false
  form: FormGroup
  constructor(private fb: FormBuilder, private contatoService: ContatoService) { }

  ngOnInit() {
    this.criarFormulario(new Contato())
  }

  criarFormulario(contato: Contato) {
    this.form = this.fb.group({
      nome: [contato.nome, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      telefone: [contato.telefone, Validators.compose([Validators.required, Validators.maxLength(11)])],
      email: [contato.email, Validators.compose([Validators.email, Validators.maxLength(160)])],
      titulo: [contato.titulo, Validators.compose([Validators.required, Validators.maxLength(140)])],
      mensagem: [contato.mensagem, Validators.compose([Validators.required, Validators.maxLength(300)])]
    })
  }

  async enviarDados() {
    const dados = this.form.value
    await this.contatoService.enviarContato(dados)
    this.status = true
    this.form.reset()
  }

}
