import { ModuleWithProviders } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { CadastroEmpresaComponent } from "./pages/cadastro-empresa/cadastro-empresa.component";
import { CadastroConvenioComponent } from "./pages/cadastro-convenio/cadastro-convenio.component";
import { ContatoComponent } from "./pages/contato/contato.component";
import { MarketingComponent } from "./pages/marketing/marketing.component";
import { DownloadComponent } from "./pages/download/download.component";
import { HomeComponent } from "./pages/home/home.component";
import { NotfoundComponent } from "./pages/notfound/notfound.component";
import { PoliticaDePrivacidadeComponent } from "./pages/politica-de-privacidade/politica-de-privacidade.component";
import { NatalFelizComponent } from "./pages/sorteios/natal-feliz/natal-feliz.component";
import { SuporteComponents } from "./pages/suporte/suporte.components";
import { TermosDeUsoComponent } from "./pages/termos-de-uso/termos-de-uso.component";
import { TrabalheConoscoComponent } from "./pages/trabalhe-conosco/trabalhe-conosco.component";

export const router: Routes = [
  { path: "", component: HomeComponent },
  { path: "termos-de-uso", component: TermosDeUsoComponent },
  {
    path: "politica-de-privacidade",
    component: PoliticaDePrivacidadeComponent,
  },
  {
    path: "sorteio-natal-feliz-2021",
    component: NatalFelizComponent,
  },
  /* Rota app pages */
  { path: "termos_de_uso.html", component: TermosDeUsoComponent },
  {
    path: "politica_de_privacidade.html",
    component: PoliticaDePrivacidadeComponent,
  },
  /*----------------- */
  { path: "cadastro-empresa", component: CadastroEmpresaComponent },
  { path: "cadastro-empresa-fn", component: CadastroEmpresaComponent },
  { path: "cadastro-convenio", component: CadastroConvenioComponent },
  { path: "trabalhe-conosco", component: TrabalheConoscoComponent },
  { path: "suporte", component: SuporteComponents },
  // { path: 'franquia', component: FranqueadoComponent },
  { path: "download", component: DownloadComponent },
  { path: "contato", component: ContatoComponent },
  { path: "marketing", component: MarketingComponent },
  { path: "**", component: NotfoundComponent },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(router, {
  preloadingStrategy: PreloadAllModules,
  useHash: false,
});
