//https://sacoleiroapp.com.br/api
//http://localhost:3001
export class Api {
    static token = "TOKEN"
    static usuarioPath = "USUARIO"
    static host = `https://sacoleiroapp.com.br/api`;
    //static host = `http://localhost:3000`;

    static getToken() {
        return localStorage.getItem(Api.token)
    }

    static getUsuario() {
        return JSON.parse(localStorage.getItem(Api.usuarioPath))
    }
}
