import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politica-de-privacidade',
  templateUrl: './politica-de-privacidade.component.html',
  styleUrls: ['./politica-de-privacidade.component.sass']
})
export class PoliticaDePrivacidadeComponent implements OnInit {
  title: String = 'Política de Privacidade'
  constructor() { }

  ngOnInit() {
  }

}
