import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Api } from "src/app.routing";


@Injectable()
export class TrabalheConoscoService {
  api = Api.host
  token = Api.getToken
  constructor(private http: HttpClient) { }

  enviarCurriculo(body, file) {
    let formData = new FormData
    formData.append("nome", body.nome)
    formData.append("telefone", body.telefone)
    formData.append("email", body.email)
    formData.append("area", body.area)
    formData.append("cpf", body.cpf)
    formData.append("extensao", body.extensao)

    for (var i = 0; i < file.length; i++) {
      formData.append("anexo", file[i], file[i].name);
    }

    return this.http.post<any>(`${this.api}/contato/curriculo`, formData).toPromise()
  }

}
